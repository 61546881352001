import { render, staticRenderFns } from "./GenderView.vue?vue&type=template&id=9085cc08&scoped=true&"
import script from "./GenderView.vue?vue&type=script&lang=js&"
export * from "./GenderView.vue?vue&type=script&lang=js&"
import style0 from "./GenderView.vue?vue&type=style&index=0&id=9085cc08&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9085cc08",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Bitnami\\jenkins-2.89.4-1\\apps\\jenkins\\jenkins_home\\jobs\\liveinfo3-front-admin-dev\\workspace\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9085cc08')) {
      api.createRecord('9085cc08', component.options)
    } else {
      api.reload('9085cc08', component.options)
    }
    module.hot.accept("./GenderView.vue?vue&type=template&id=9085cc08&scoped=true&", function () {
      api.rerender('9085cc08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/statistics/sign-draw/view/GenderView.vue"
export default component.exports